import React from "react";

import {
  YEAR,
  VERSION,
  MAIN_TITLE,
} from "../utils/appConstants";
import "../components/Login/login.css";
import InfoIcon from "@material-ui/icons/Info";
import AcnLogo from "../assets/svgs/AcnBlackPurpleLogo.svg";

const Error = () => {
  return (
    <div className="error-root">
    <div className="login-header">
      <img src={AcnLogo} alt="Accenture Logo" width="50%" height="50%" />
    </div>
    <div className="login-content">
      <div className="login-content-inner">
        <div className="login-content-heading">Thanks for using {MAIN_TITLE}!</div>
        <div style={{ display: "flex", fontSize: "16px", textAlign: "center" }}>
          {/* <InfoIcon style={{ fill: "#ed6c02" }} /> */}
            <div>
              As of April 26, 2024, the app has been moved to the new 360 Value Navigator. To experience it, please go to
              {" "}
              <a target="__blank" href="https://360vn.accenture.com">360 Value Navigator</a>
            </div>
        </div>
        <div style={{ color: "gray", marginTop: "2vh", fontSize: "14px", textAlign: "center" }}>
          For assistance, you may also contact <span style={{ color: "blue" }}>360VNcomms@accenture.com</span>.
        </div>
      </div>
    </div>
    <div className="login-footer">
      <div style={{ fontWeight: "bold" }}>Version {VERSION}</div>
      <div>Built for Edge, Chrome, Firefox.</div>
      <div>
        © {YEAR} {MAIN_TITLE} and its related entities. All Rights Reserved.
      </div>
    </div>
  </div>
  );
};

export default Error;
