import { useRecoilState, useRecoilValue } from "recoil";
import React, { useRef, useState, useEffect } from "react";

import "./message.css";
import TextMessage from "./TextMessage";
import HtmlMessage from "./HtmlMessage";
import FileMessage from "./FileMessage";
import EmojiMessage from "./EmojiMessage";
import TableMessage from "./TableMessage";
import ButtonMessage from "./ButtonMessage";
import CollibraFaqMessage from "./CollibraFaqMessage";
import { postRegenerate } from "../../../../api/api";
import ThumbUp from "../../../../assets/svgs/Thumb_up.svg";
import FeedbackDialog from "../../../Shared/feedbackDialog";
import ThumbDown from "../../../../assets/svgs/Thumb_down.svg";
import { defaultHeight } from "../../../../utils/appConstants";
import LearnMorePopover from "../../../Shared/learnMorePopover";
import Regenerate from "../../../../assets/svgs/regenerate.svg";
import ChatterSvg from "../../../../assets/svgs/Icon-Chatter.svg";
import ThumbUpBlk from "../../../../assets/svgs/Thumb_up_blk.svg";
import ChevronLeft from "../../../../assets/svgs/chevron_left.svg";
import ChevronRight from "../../../../assets/svgs/chevron_right.svg";
import ThumbDownBlk from "../../../../assets/svgs/Thumb_down_blk.svg";
import { lastBotResp, chatMesgState } from "../../../../recoil/atoms";
import AuthenticationService from "../../../../utils/authenticationService";

const TextTime = ({ data }) => {
  return (
    <span className="fsm-message--date">
      {new Date(
        data?.created_date?.[data?.created_date?.length - 1] === "Z"
          ? data?.created_date
          : data?.created_date + "Z"
      ).toLocaleTimeString(navigator.language, {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Asia/Kolkata",
      })}
    </span>
  );
};

const MessageIndex = (props) => {
  const renderMessageOfType = (msg, msg_id, res_code) => {
    switch (msg?.type) {
      case "text":
        return <TextMessage {...msg} msg_id={msg_id} />;
      case "html":
        return <HtmlMessage {...msg} />;
      case "file":
        return <FileMessage {...msg} />;
      case "emoji":
        return <EmojiMessage {...msg} />;
      case "table":
        return <TableMessage {...msg} />;
      case "collibra_faq":
        return <CollibraFaqMessage {...msg} />;
      case "button":
        return <ButtonMessage {...msg} msg_id={msg_id} res_code={res_code} />;
      default:
        console.error(
          `Attempting to load message with unsupported file type '${msg?.type}'`
        );
    }
  };

  let contentClassList = [
    "fsm-message",
    props.message.author ===
    parseInt(AuthenticationService.getLocalStorageUserId())
      ? "fsm-message-out"
      : "fsm-message-in",
  ];

  let textRootClassList = [
    "fsm-message--text",
    props.message.author ===
    parseInt(AuthenticationService.getLocalStorageUserId())
      ? "fsm-message--outtext"
      : "fsm-message--intext",
  ];

  const [openModal, setOpenModal] = useState({
    type: 0,
    data: null,
    state: false,
    msg_id: null,
  });
  const [openHover, setOpenHover] = useState({
    data: null,
    anchorEl: null,
  });
  const lastBotMsg = useRecoilValue(lastBotResp);
  const [regenerateNum, setRegenerateNum] = useState(0);
  const [messageList, setMessageList] = useRecoilState(chatMesgState);

  // const elementRef = useRef(null);
  // const [trigCalc, setTrigCalc] = useState(false);
  // const [showMore, setShowMore] = useState(false);
  // const [isOverflow, setIsOverflow] = useState(false);
  // const [heightCur, setHeightCur] = useState(defaultHeight);
  // const [heightMax, setHeightMax] = useState(defaultHeight);

  // useEffect(() => {
  //   if (trigCalc) {
  //     const element = document.getElementById(`h-${props?.message?.msg_id}`);
  //     const heightClient = element?.clientHeight || defaultHeight;
  //     if (heightClient > defaultHeight) {
  //       setIsOverflow(true);
  //       setHeightMax(elementRef?.current?.scrollHeight);
  //     }
  //   }
  // }, [trigCalc]);

  const handleOpenDialog = (props, value, m_id) => {
    setOpenModal({ type: value, data: props, state: true, msg_id: m_id });
  };

  const handleCloseDialog = () => {
    setOpenModal({ type: 0, data: null, state: false, msg_id: null });
  };

  const handlePopoverOpen = (event, props) => {
    setOpenHover({ data: props, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setOpenHover({ data: null, anchorEl: null });
  };

  const openLMP = Boolean(openHover?.anchorEl);

  const handleRegenerate = async (data) => {
    const tempObj = data?.message?.data?.[regenerateNum];
    let tempReqObj = {
      res_code: tempObj?.res_code,
      msg_id: data?.message?.msg_id,
      category: data?.message?.category,
      query_id: data?.message?.query_id,
      previous_version: tempObj?.version,
      previous_response: tempObj?.response?.[0]?.text,
    };
    const resp = await postRegenerate(tempReqObj);
    if (resp) {
      let tempMsgList = structuredClone(messageList);
      messageList?.data &&
        Object.keys(messageList.data).length > 0 &&
        Object.keys(messageList.data).forEach((date) => {
          let tempDateMsgList = [...tempMsgList.data[`${date}`]];
          let msgIndex =
            tempDateMsgList?.length > 0 &&
            tempDateMsgList.findIndex(
              (d) => d?.msg_id === data?.message?.msg_id
            );
          if (msgIndex > -1) {
            tempDateMsgList[msgIndex]["data"].push(resp?.data?.[0]);
            tempMsgList.data[`${date}`] = tempDateMsgList;
            return;
          }
        });
      setMessageList(tempMsgList);
      setRegenerateNum(regenerateNum + 1);
    }
  };

  return (
    <div className={contentClassList.join(" ")}>
      <div className="fsm-message--text-root">
        <div className={textRootClassList.join(" ")}>
          {props?.message?.author !==
          AuthenticationService.getLocalStorageUserId() ? (
            <>
              <div
                className={`fsm-message--intext-left ${
                  props?.message?.data?.length > 1
                    ? "fsm-message--intext-left-width-regenerate"
                    : "fsm-message--intext-left-width"
                }`}
              >
                <img
                  alt="Chatter"
                  src={ChatterSvg}
                  style={{
                    maxWidth: props?.message?.data?.length > 1 ? "50%" : "100%",
                  }}
                />
                {props?.message?.data?.length > 1 ? (
                  <div className="fsm-message--slider">
                    <img
                      alt="ChevronLeft"
                      src={ChevronLeft}
                      style={{ maxWidth: "100%" }}
                      onClick={() => setRegenerateNum(regenerateNum - 1)}
                      className={
                        regenerateNum === 0
                          ? "fsm-message--regenerate-disabled"
                          : "fsm-message--regenerate-enabled"
                      }
                    />
                    {regenerateNum + 1} / {props?.message?.data?.length}
                    <img
                      alt="ChevronRight"
                      src={ChevronRight}
                      style={{ maxWidth: "100%" }}
                      onClick={() => setRegenerateNum(regenerateNum + 1)}
                      className={
                        regenerateNum === props?.message?.data?.length - 1
                          ? "fsm-message--regenerate-disabled"
                          : "fsm-message--regenerate-enabled"
                      }
                    />
                  </div>
                ) : null}
              </div>

              <div
                className={`fsm-message--intext-right ${
                  props?.message?.data?.length > 1
                    ? "fsm-message--intext-right-width-regenerate"
                    : "fsm-message--intext-right-width"
                }`}
              >
                <div
                // ref={elementRef}
                // id={`h-${props?.message?.msg_id}`}
                // style={{ height: isOverflow ? showMore ?
                //   `${heightMax}px` : `${defaultHeight}px` : "",
                //   overflow: "hidden"
                // }}
                >
                  {props?.message?.data?.[regenerateNum]?.["response"]?.length >
                    0 &&
                    props.message.data[regenerateNum]["response"].map(
                      (msg, ind) => {
                        // if (ind === (props?.message?.data?.length) - 1) {
                        //   setTimeout(() => {
                        //     setTrigCalc(true);
                        //   }, 0);
                        // }
                        return (
                          <React.Fragment key={ind}>
                            {renderMessageOfType(msg, props?.message?.msg_id, props?.message?.data?.[regenerateNum]?.res_code)}
                          </React.Fragment>
                        );
                      }
                    )}
                </div>

                {/* {isOverflow ? (
                  <div
                    className="fsm-message--show"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </div>
                ) : null} */}

                {props?.message?.msg_id !== -1 ? (
                  <div className="fsm-message--time-feedback">
                    <div className="fsm-message--feedback-container">
                      {props?.message?.data?.[regenerateNum]?.feedback === 1 ||
                      props?.message?.data?.[regenerateNum]?.feedback === 0 ? (
                        <img
                          alt="ThumbUp"
                          className={
                            props?.message?.data?.[regenerateNum]?.feedback ===
                            1
                              ? "fsm-message--feedback-image-disabled"
                              : "fsm-message--feedback-image"
                          }
                          onClick={() =>
                            handleOpenDialog(
                              props?.message?.data?.[regenerateNum],
                              1,
                              props?.message?.msg_id
                            )
                          }
                          src={
                            props?.message?.data?.[regenerateNum]?.feedback ===
                            1
                              ? ThumbUpBlk
                              : ThumbUp
                          }
                        />
                      ) : null}
                      {props?.message?.data?.[regenerateNum]?.feedback === -1 ||
                      props?.message?.data?.[regenerateNum]?.feedback === 0 ? (
                        <img
                          alt="ThumbUp"
                          className={
                            props?.message?.data?.[regenerateNum]?.feedback ===
                            -1
                              ? "fsm-message--feedback-image-disabled"
                              : "fsm-message--feedback-image"
                          }
                          onClick={() =>
                            handleOpenDialog(
                              props?.message?.data?.[regenerateNum],
                              -1,
                              props?.message?.msg_id
                            )
                          }
                          src={
                            props?.message?.data?.[regenerateNum]?.feedback ===
                            -1
                              ? ThumbDownBlk
                              : ThumbDown
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {props?.message?.msg_id !== -1 ? (
                  <div className="fsm-message--score-regenerate">
                    <div className="fsm-message--score-regenerate-left">
                      {props?.message?.data?.[regenerateNum]?.rating ? (
                        <div className="fsm-message--score">
                          This answer has a
                          <span className="fsm-message--score-hightlight">
                            {" "}
                            {props?.message?.data?.[regenerateNum]?.rating}
                          </span>{" "}
                          confidence score.
                          <span
                            onMouseLeave={handlePopoverClose}
                            className="fsm-message--score-learnmore"
                            aria-owns={
                              openLMP ? "mouse-over-popover" : undefined
                            }
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                props?.message?.data?.[regenerateNum]
                              )
                            }
                          >
                            {" "}
                            Learn more
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className="fsm-message--score-regenerate-right">
                      {regenerateNum === props?.message?.data?.length - 1 &&
                      props?.message?.author?.includes("genai") &&
                      [0, 1, 3].includes(
                        props?.message?.data?.[regenerateNum]?.res_code
                      ) &&
                      lastBotMsg?.msg_id === props?.message?.msg_id ? (
                        <div
                          className="fsm-message--regenerate"
                          onClick={() => handleRegenerate(props)}
                        >
                          Regenerate response
                          <img alt="Regenerate" src={Regenerate} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <TextTime data={props?.message?.data?.[regenerateNum]} />
              </div>
            </>
          ) : (
            <React.Fragment>
              {props?.message?.data?.[regenerateNum]?.["response"]?.length >
                0 &&
                props.message.data[regenerateNum]["response"].map(
                  (msg, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        {renderMessageOfType(msg, props?.message?.msg_id)}
                      </React.Fragment>
                    );
                  }
                )}
              <TextTime data={props?.message} />
            </React.Fragment>
          )}
        </div>
      </div>
      {openModal?.state && (
        <FeedbackDialog
          data={openModal}
          messageList={messageList}
          openModal={openModal.state}
          regenerateNum={regenerateNum}
          setMessageList={setMessageList}
          handleClose={handleCloseDialog}
        />
      )}
      {openLMP && (
        <LearnMorePopover
          open={openLMP}
          data={openHover?.data}
          anchorEl={openHover?.anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      )}
    </div>
  );
};

export default MessageIndex;
