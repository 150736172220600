import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0em",
    fontFamily: "Graphik",
    pointerEvents: "none",
  },
  paper: {
    width: "315px",
    boxSizing: "border-box",
    padding: theme.spacing(1),
  },
}));

const lowText = "Low confidence responses have not passed quality standards.";
const mediumText =
  "Medium confidence responses have passed some but not all quality standards.";
const highText =
  "High confidence responses have passed quality standards for data and AI-generated content.";

const lowResp = "Info may be wrong or inaccurate.";
const mediumResp = "Use information with caution.";
const highResp = "Verify before use in business decisions.";

const commonText =
  "To improve the confidence rating of a GenNav response:\n* Consider rewording your question.\n* Use specific parameters such as Market, Service, or Client groups, as well as identifying a time period (QTD, YTD) and format (QoQ, YoY).";

const LearnMorePopover = ({ open, anchorEl, data, handlePopoverClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        disableRestoreFocus
        id="mouse-over-popover"
        className={classes.popover}
        onClose={handlePopoverClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <div>
          {data?.rating?.toLowerCase() === "high"
            ? highText
            : data?.rating?.toLowerCase() === "low"
            ? lowText
            : mediumText}
        </div>
        <div style={{ fontWeight: 600 }}>
          {data?.rating?.toLowerCase() === "high"
            ? highResp
            : data?.rating?.toLowerCase() === "low"
            ? lowResp
            : mediumResp}
        </div>
        <div style={{ whiteSpace: "pre-wrap" }}>{commonText}</div>
      </Popover>
    </div>
  );
};

export default LearnMorePopover;
