export const staticSuggestions = [
  {
    "question": "What are the top negative drivers of profitability in North America",
  },
  {
    "question": "What is the Qualified Pipeline in EMEA for FY23?",
  }
];

export const staticFAQS = [
  {
    "type": "metric",
    "query": "Which databases does GenNav use to answer questions about KPIs and insights?",
    "answer": "GenNav draws insights and KPIs from dashboards in 360 Value Navigator. For release 2.0, GenNav is only trained to pull from State of the Business, E2E Profitability and Financial.",
    "link": [{"text": "Please click here.", "type": "metric"}]
  },
  {
    "type": "question",
    "query": "Are there examples of questions GenNav can answer?",
    "answer": "Here is a list of questions it can address. Use them to guide the questions you ask GenNav.",
    "link": [{"text": "Please click here.", "type": "question"}]
  },
  {
    "type": "normal",
    "query": "Are there any best practices that a user should abide by when interacting with the GenNav?",
    "answer": "1. When asking for key performance indicators (KPIs) of an entity, specify the name of the Market, Market Unit, Service Group or Client Group in your conversation.\n\n2. Indicate MTD, MoM, QTD, QoQ, YTD and YoY in your query. Otherwise GenNav will provide KPIs in YTD by default.\n\n3. Include the names of KPIs in conversations with GenNav, e.g., Won CCI, Resale Revenue, Pyramid Index, etc.\n\nFor CCI-related questions, answers will default to Delivered CCI. For revenue-related questions, answers will default to Revenue.\n\n4. Use the term “global” when you want to view global KPIs.\n\n5. Avoid use of the percentage character '%' in your entries as GenNav is still improving its model to identify this symbol.",
    "link": null
  },
  {
    "type": "normal",
    "query": "What dimensions of metrics can GenNav process?",
    "answer": "The GenNav can provide responses based on the dashboard source as follows (dimensions per metric may vary):\n\n State of Business Dashboard:\n • Market - North America, EMEA, Growth Markets\n • Market Unit - Canada, Latin America, India, Japan etc.\n • Service - Strategy and Consulting Operations, Technology\n • Service Group -  Strategy Consulting, Application Managed Services, Consulting etc.\n • Industry Group - Resources, Products, Financial Services etc.\n • FY - FY19 to FY24\n • Quarters - Q1, Q2, Q3, Q4\n\n E2E Profitability Dashboard:\n • Market - North America, EMEA, Growth Markets\n • Market Unit - Canada, ICEG, India, Japan etc.\n • Service Group -  Strategy Consulting, Application Managed Services, Consulting etc.\n • Client Group - CMT Other, H&PS Border Mgt & PS, FS Other, etc.\n • Period - MTD, QTD, YTD, QOQ, YOY\n\n Financial Summary Dashboard:\n • Market - North America, EMEA, Growth Markets\n • Period - MTD, QTD, YTD",
    "link": null
  },
  {
    "type": "normal",
    "query": "Tell me about the dashboard data sources.",
    "answer": "The SoB provides a concise, top-line, most up-to-date summary report on financial metrics and operational insights to the GMC with values at constant currency.\n\nEnd-to-End Profitability provides a curated and chauffeured experience flow to the reports, analytic solutions, models, and tools that are needed to manage end-to-end profit with values at global currency.\n\nFinancial Summary offers an executive dashboard consolidating all essential Financial 360 Value Lever KPIs into a unified, standardized view for informed executive decision-making with values at constant currency.",
    "link": null
  },
];