import React, { useState } from "react";
import { useRecoilState } from "recoil";

import {
  MSG_OBJ,
  MAIN_TITLE,
  TOLOCALE_LOCALES,
  TOLOCALE_OPTIONS,
} from "../../../../utils/appConstants";
import Input from "../../../Shared/input";
import SendSvg from "../../../../assets/svgs/Send.svg";
import { uuidv4M3 } from "../../../../utils/appFunction";
import { chatMesgState } from "../../../../recoil/atoms";
import AuthenticationService from "../../../../utils/authenticationService";

const FSMChatFooter = () => {
  const [typeMessage, setTypeMessage] = useState("");
  const [messageList, setMessageList] = useRecoilState(chatMesgState);

  const handleTypeMessageChange = (key, val) => {
    setTypeMessage(val);
  };

  const handleSendTypeMessage = () => {
    if (typeMessage !== "") {
      let tempMsgObj = structuredClone(MSG_OBJ);
      tempMsgObj["id"] = uuidv4M3();
      tempMsgObj["created_date"] = new Date().toISOString();
      const tempDataObj = { response: [{ type: "text", text: typeMessage }] };
      tempMsgObj["data"].push(tempDataObj);
      tempMsgObj["author"] = parseInt(
        AuthenticationService.getLocalStorageUserId()
      );
      let tempMsgList = structuredClone(messageList);
      let currentDate = new Date().toLocaleDateString(
        TOLOCALE_LOCALES,
        TOLOCALE_OPTIONS
      );
      // messageList.hasOwnProperty('currentDate') or currentDate in Object.keys(messageList)
      if (messageList?.data.hasOwnProperty(currentDate)) {
        // today date is in Object.keys and push message in the array
        let tempCurrentDateMsgList = [
          ...tempMsgList.data[`${currentDate}`],
          tempMsgObj,
        ];
        tempMsgList.data[`${currentDate}`] = tempCurrentDateMsgList;
      } else {
        // add today date in Object.keys and push message in the array
        tempMsgList.data[`${currentDate}`] = [tempMsgObj];
      }
      tempMsgList.wsSendMsg = tempMsgObj;
      setMessageList(tempMsgList);
      setTypeMessage("");
    }
  };

  const handleKeyDownTypeMessage = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Calls the Login API
      handleSendTypeMessage();
    }
  };

  return (
    <React.Fragment>
      <div className="fsm-chat-footer-box-container">
        <div className="fsm-chat-footer-box">
          <div className="fsm-chat-footer-box-left">
            <Input
              type="text"
              label="nolabel"
              id="typeMessage"
              noBorder={true}
              value={typeMessage}
              noMarginBottom={true}
              handleChange={handleTypeMessageChange}
              handleKeyDown={handleKeyDownTypeMessage}
              placeholder="Ask a question about 360 Value Insights."
            />
          </div>
          <div className="fsm-chat-footer-box-right">
            <img
              src={SendSvg}
              alt="Send message"
              style={{ cursor: "pointer" }}
              onClick={() => handleSendTypeMessage()}
            />
          </div>
        </div>
        <div className="fsm-chat-footer-container-msg">
          Ask {MAIN_TITLE} is powered by AI. Please verify accuracy, privacy
          restrictions and data security before using content for business
          decisions.
        </div>
      </div>
    </React.Fragment>
  );
};

export default FSMChatFooter;
