import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { RETENTION_MSG } from "../../../../utils/appConstants";

const FSMChatHeader = ({ chatPagination, setChatPagination, setOpenChatHeader }) => {

  const handleLoadConversationalHistory = () => {
    setChatPagination({
      ...chatPagination,
      isScroll: true,
      noRefresh: false,
      skip: chatPagination?.skip + 1,
    });
    setOpenChatHeader(false);
  }

  return (
    <div className="fsm-chat-header-box">
      <div className="fsm-chat-header-left">
        {RETENTION_MSG}{" "}
        <span
          onClick={() => handleLoadConversationalHistory()}
          style={{ color: "#FF00FF", cursor: "pointer", textDecoration: "underline" }}
        >
          Click here to load conversation history
        </span>
      </div>
      <div className="fsm-chat-header-right">
        <IconButton
          aria-label="close"
          onClick={() => setOpenChatHeader(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default FSMChatHeader;
