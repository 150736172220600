import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PdfDialog from "../../../Shared/pdfDialog";

const metricAnswer = (faq, modalOpener) => {
  return (
    <div>
      {/* {faq?.answer} */}
      GenNav draws insights and KPIs from dashboards in 360 Value Navigator. For release 2.0, GenNav is only trained to pull from <a className="link" onClick={() => modalOpener("metric")}>State of the Business, E2E Profitability and Financial.</a>
    </div>
  )
}

const sampleQuestionAnswer = (faq, modalOpener) => {
  return (
    <div>
      {/* {faq?.answer} */}
      Here is a <a className="link" onClick={() => modalOpener("question")}>list of questions</a> it can address. Use them to guide the questions you ask GenNav.
    </div>
  )
}

const FaqAccordian = ({ key, faq }) => {
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpenDialog = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const modalOpener = (type) => {
    if (type === "metric") {
      setType("Metric list");
      handleOpenDialog();
    } else if (type === "question") {
      setType("Sample questions");
      handleOpenDialog();
    }
  }

  return (
    <div
      className="fsm-sidebar-content-faq-root"
      style={{ background: isExpanded ? "#1D1823" : "" }}
    >
      <div
        onClick={() => {
          if (isExpanded) {
            setIsExpanded(false);
          } else {
            setIsExpanded(true);
          }
        }}
        className="fsm-sidebar-content-faq-ques-root"
      >
        <div className="fsm-sidebar-content-faq-ques">{faq?.query}</div>
        <div className="fsm-sidebar-content-faq-icon">
          {isExpanded ? (
            <ExpandLessIcon
              fontSize="medium"
              onClick={() => setIsExpanded(false)}
              style={{ fill: "#ffffff", cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="medium"
              onClick={() => setIsExpanded(true)}
              style={{ fill: "#ffffff", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        // <div className="fsm-sidebar-content-faq-ans">{faq?.answer}</div>
        <div className="fsm-sidebar-content-faq-ans">
          {faq?.type === "normal" ? faq?.answer : faq?.type === "metric" ?
          metricAnswer(faq, modalOpener): sampleQuestionAnswer(faq, modalOpener)}
        </div>
      ) : null}
      <Divider color="#4F4B53" style={{ backgroundColor: "#4F4B53" }} />
      {openModal && <PdfDialog type={type} openModal={openModal} handleClose={handleCloseDialog} />}
    </div>
  );
};

export default FaqAccordian;
